import React from 'react'
import './AboutComp.scss'
import { Box, Typography } from '@mui/material'

export const ManagerSpeech = () => {
  return (
    <div className="manager-speech-wrapper">
      <Box maxWidth="lg" className="manager-speech tb-space">
        <div className="left">
          <img src="/images/manager-img.webp" alt="general manager" />
        </div>
        <div className="right">
          <Typography variant="h2" className="main-title">
            Chen Xuebing
          </Typography>
          <Typography className="sub-title">General Manager</Typography>
          <Typography className="text">
            The past 2021 was a year of transformation and a key year for
            planning and development. Anke company has successfully transformed
            from functional department of the West International Holdings group
            into an independent firm. our staffs have made concerted efforts to
            complete the tasks and objectives of 2021, under the guidance idea
            of "strengthening the foundation, preventing risks and speeding up
            development.
          </Typography>
        </div>
      </Box>
    </div>
  )
}
