import React from 'react'
import { SolutionContainer } from '../components/solutions/SolutionContainer'
import { Banner } from './../components/common/Banner';

export const Solutions = () => {
  return (
    <div>
      <Banner title="Our Solutions" img="/images/banner-img-4.jpg" />
      <SolutionContainer />
    </div>
  )
}
