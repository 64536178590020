import React from "react";
import { Link } from "react-router-dom";
import { Container, Box, Button, Typography } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
let theme = createTheme();
theme = responsiveFontSizes(theme);
// let theme = createTheme({
//   typography: {
//     h1: {
//       fontSize: 65,
//       fontWeight: 700,
//     },
//     fontFamily: ["Poppins", "sans-serif"].join(","),
//   },
// });
// theme = responsiveFontSizes(theme);

const SliderContent = ({ activeIndex, sliderImage }) => {
  return (
    <>
      {sliderImage.map((slide, index) => (
        <Box
          key={index}
          className={index === activeIndex ? "slides active " : "inactive"}
        >
          <Box className="slider-headline-body ">
            <img className="slide-image" src={slide.urls} alt="" />
            <Box maxWidth="lg" className="hero-content tb-space">
              <ThemeProvider theme={theme}>
                <Typography variant="h1" sx={{ fontWeight: 700 }}>
                  {slide.title}{" "}
                  <span className="colored-title">{slide.colorTitle}</span>
                  {slide.title2}{" "}
                  <span className="colored-title">{slide.colorTitle2}</span>
                </Typography>
              </ThemeProvider>
              <Link to="/contact">
                <Button
                  variant="outlined"
                  sx={{
                    ":hover": {
                      color: "primary",
                    },
                  }}
                  className="btn-outlined"
                >
                  Get In Touch
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default SliderContent;
