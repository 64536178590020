import { Box, Button } from '@mui/material'
import React from 'react'
import { Typography } from '@mui/material';
import "./HomeAbout.scss"
import { useNavigate } from 'react-router-dom';

export const HomeAbout = () => {
  const navigate = useNavigate()
  return (
    <Box className='home-about-wrapper'>
      <Box maxWidth='lg' className='home-about tb-space'>
        <Box className='left'>
          <Typography variant='h2'>Anke Co- Creative Technologies</Typography>
          <Typography variant='body2'>We are committed to provide professional, standard and easy-to-use informatization, intelligent products and Data integration solution services.</Typography>
          <Button onClick={()=> navigate('/about')} className='btn-outlined' variant='outlined'>Learn More</Button>
        </Box>
        <Box className='right'>
          <video autoPlay='true' muted loop src='/videos/home-about-video.mp4'/>
        </Box>
      </Box>
    </Box>
  )
}
