import { Box, Button, Card, CardActionArea, Grid, Typography } from '@mui/material'
import React from 'react'
import './HomeServices.scss'
import { useNavigate } from 'react-router-dom'


const data = [
  {
    title: 'System Solutions',
    text:
      'Efficient implementation, seamless integration, and reliable maintenance for comprehensive system solutions.',
    img: '/images/service-card-img-1.jpg',
    icon: '/images/service-card-icon-1.png',
    path: '/solutions/system-solutions'
  },
  {
    title: 'Digital Infrastructure',
    text:
      'Efficient setup and maintenance of network infrastructure, surveillance cameras, and large LED displays.',
    img: '/images/service-card-img-2.jpg',
    icon: '/images/service-card-icon-2.png',
    path: '/solutions/digital-infrastructure'
  },
  {
    title: 'Industrial Automation',
    text:
      'Automated control of industrial operations, enhancing efficiency with DCS and PLC platforms.',
    img: '/images/service-card-img-3.jpg',
    icon: '/images/service-card-icon-3.png',
    path: '/solutions/industrial-automation'
  },
]
export const HomeServices = () => {
  const navigate = useNavigate()
  return (
    <div className="home-services-wrapper">
      <Box maxWidth="lg" className="home-services tb-space">
        <Box className="top">
          <Button className="our-service-top-btn">Our Services</Button>
          <Typography variant="h2" className="top-header">
            IT Solutions Services
          </Typography>
        </Box>
        <Grid className="bottom" container r  rowSpacing={3}
          columnSpacing={3}
                >
          {data.map((data, idx) => {
            return (
                <Grid item sx={12} sm={6} md={4} >
                    <Card key={idx} className="card" onClick={()=> navigate(data.path)}>
                <CardActionArea>
                  <div className="card-img">
                    <img src={data.img} alt="card img" />
                  </div>
                  <div className="card-content">
                    <div className="left-bar"></div>
                    <div className="right-bar"></div>
                    <div className="card-top">
                      <div className="icon-wrapper">
                        {' '}
                        <img src={data.icon} alt="card icon" />
                      </div>
                      <Typography variant="h3">{data.title}</Typography>
                    </div>
                    <Typography className="card-bottom-text">
                      {data.text}
                    </Typography>
                  </div>
                  <div className="bottom-btn" >
                    <Typography className="bottom-btn-text">
                      LEARN MORE
                    </Typography>
                  </div>
                </CardActionArea>
              </Card>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </div>
  )
}
