import React from 'react'
import { Banner } from './../components/common/Banner';
import { ContactComp } from '../components/contact/ContactComp';

export const Contact = () => {
  return (
    <div>
      <Banner title="Contact Us" img="/images/banner-img-2.webp" />
      <ContactComp />
    </div>
  )
}
