import React from "react";
import ProjectContainer from "../components/projects/ProjectContainer";
import { Banner } from "../components/common/Banner";
export const Projects = () => {
  return (
    <div>
      <Banner title="Projects" img="/images/banner-img-3.webp" />
      <ProjectContainer />
    </div>
  );
};
