import React, { useEffect, useState } from 'react'
import './solutions.scss'
import { SolutionCard } from './SolutionCard'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
const data1 = [
  {
    icon: '/images/service-cat-1-icon-1.png',
    title: 'Enterprise Resource Planning (ERP)',
    text:
      'Boost your business with our ERP solutions featuring ORACLE NetSuite. Integrate accounting, ERP, CRM, and ecommerce software for streamlined data management and interpretation.',
  },
  {
    icon: '/images/service-cat-1-icon-2.png',
    title: 'System Integration Soultions',
    text:
      'Optimize your manufacturing process with our MES software. Track, document, and control production from raw materials to finished products, improving output with real-time plant floor insights.',
  },
  {
    icon: '/images/service-cat-1-icon-3.png',
    title: 'Transport Management System (TMS)',
    text:
      'Streamline your supply chain with our Transportation Management System (TMS). Efficiently plan, execute, and measure deliveries, bridging the gap between ERP systems and warehouse/distribution modules.',
  },
  {
    icon: '/images/service-cat-1-icon-4.png',
    title: 'Office Automation (OA)',
    text:
      'Boost productivity with our Office Automation (OA) tools. Seamlessly integrate and exchange data across systems, securely collect, manage, and analyze information, while simplifying everyday tasks and processes.',
  },
  {
    icon: '/images/service-cat-1-icon-5.png',
    title: 'Manufacturing Execution System (MES)',
    text:
      'Experience seamless digital transformation with our comprehensive solutions. We specialize in designing, installing, and integrating hardware and software systems to optimize IT infrastructure, maximize ROI, and create live operating environments.',
  },
  {
    icon: '/images/service-cat-1-icon-6.png',
    title: 'Training Services',
    text:
      'Our training services aid in adapting to new roles and processes, enabling customers to unleash technology\'s full potential and become proficient super users.',
  },
]

const data2 = [
  {
    icon: '/images/service-cat-2-icon-1.png',
    title: 'Network Installation, Cabling and Wireless Network',
    text:
      'We provide training to optimize technology usage and develop proficient super users. Our services include network design, installation, configuration, and audio/video technologies.',
  },
  {
    icon: '/images/service-cat-2-icon-2.png',
    title: 'Surveillance Camera and Large LED Screens',
    text:
      'We offer technology training, network design, installation, configuration, and audio/video services. Our experts install security camera systems flawlessly and provide maintenance.',
  },
]

const data3 = [
  {
    icon: '/images/service-cat-3-icon-1.png',
    title: 'Distributed Control System(DCS)',
    text:
      'We provide a comprehensive DCS platform for automated control of complex production processes, integrating sensors, controllers, computers, HMI, logic solvers, historian, database, and alarm management.',
  },
  {
    icon: '/images/service-cat-3-icon-2.png',
    title: 'Programmable Logic Control (PLC)',
    text:
      'Utilizing digital computing, the Programmable Logic Controller (PLC) revolutionizes industries by automating electromechanical processes, ensuring precision and achieving desired outcomes.',
  },
]
export const SolutionContainer = () => {
  const {pid} = useParams()
  const [active, setActive] = useState({
    btn1: true,
    btn2: false,
    btn3: false,
  })

  useEffect(() => {
    if (pid == 'system-solutions') {
      setActive({
        btn1: true,
        btn2: false,
        btn3: false,
      })
    } else if (pid == 'digital-infrastructure') {
      setActive({
        btn1: false,
        btn2: true,
        btn3: false,
      })
    } else if (pid == 'industrial-automation') {
      setActive({
        btn1: false,
        btn2: false,
        btn3: true,
      })
    }
 },[])
  return (
    <div className="solution-container-wrapper">
      <Box maxWidth="lg" className="solution-container tb-space">
        <div className="top">
          <Button className="our-service-top-btn">Our Solutions</Button>
          <Typography className="text">
            We specialize in scalable solutions to promote digitalization and
            intelligence for businesses. We offer professional and user-friendly
            products and data integration solutions.
          </Typography>
          <div className="btn-container">
            <Button
              className={active.btn1 ? 'active' : ''}
              onClick={() =>
                setActive({
                  btn1: true,
                  btn2: false,
                  btn3: false,
                })
              }
            >
              System Solutions
            </Button>
            <Button
              onClick={() =>
                setActive({
                  btn1: false,
                  btn2: true,
                  btn3: false,
                })
              }
              className={active.btn2 ? 'active' : ''}
            >
              Digital Infrastructure
            </Button>
            <Button
              onClick={() =>
                setActive({
                  btn1: false,
                  btn2: false,
                  btn3: true,
                })
              }
              className={active.btn3 ? 'active' : ''}
            >
              Industrial Automation
            </Button>
          </div>
        </div>
        {
          <Grid
            container
            rowSpacing={8}
            columnSpacing={3}
            className="cards-container"
          >
            {active.btn1 &&
              data1.map((data, idx) => {
                return (
                  <Grid item xs={12} md={6} className="grid-item">
                    <SolutionCard
                      key={idx}
                      icon={data.icon}
                      title={data.title}
                      text={data.text}
                    />
                  </Grid>
                )
              })}
          </Grid>
        }

        {active.btn2 && (
          <Grid
            container
            rowSpacing={8}
            columnSpacing={3}
            className="cards-container"
          >
            {data2.map((data, idx) => {
              return (
                <Grid item xs={12} md={6} className="grid-item">
                  <SolutionCard
                    key={idx}
                    icon={data.icon}
                    title={data.title}
                    text={data.text}
                  />
                </Grid>
              )
            })}
          </Grid>
        )}

        {active.btn3 && (
          <Grid
            container
            rowSpacing={8}
            columnSpacing={3}
            className="cards-container"
          >
            {data3.map((data, idx) => {
              return (
                <Grid item xs={12} md={6} className="grid-item">
                  <SolutionCard
                    key={idx}
                    icon={data.icon}
                    title={data.title}
                    text={data.text}
                  />
                </Grid>
              )
            })}
          </Grid>
        )}
      </Box>
    </div>
  )
}
