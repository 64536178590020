import { Routes, Route, useLocation } from "react-router-dom";
import "./styles2.scss";
import { Footer } from "./components/common/Footer/Footer";
import { Navbar } from "./components/common/Navbar/Navbar";
import { Home } from "./routes/Home";
import { About } from "./routes/About";
import { Projects } from "./routes/Projects";
import { Contact } from "./routes/Contact";
import { Solutions } from "./routes/Solutions";
import { useEffect } from "react";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className="app">
      <div id="top">
        <Navbar />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/solutions/:pid" element={<Solutions />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
