import React from 'react'
import { AboutComp } from '../components/about/AboutComp'
import { ManagerSpeech } from '../components/about/ManagerSpeech'
import { Banner } from '../components/common/Banner'

export const About = () => {
  return (
    <div>
      <Banner img="/images/AboutBg2.png" title="About Us"/>
      <AboutComp />
      <ManagerSpeech />
    </div>
  )
}
