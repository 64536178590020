import React, { useState, useEffect } from "react";
import "./HomeBanner.scss";
import SliderContent from "./SliderContent";
import Dots from "./Dot";
import Arrows from "./Arrows";
import Banner1 from "../../../assets/Banner1.webp";
import Banner2 from "../../../assets/Banner2.webp";
import Banner3 from "../../../assets/Banner3.webp";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { Container, Box } from "@mui/material";
let theme = createTheme();
theme = responsiveFontSizes(theme);
const HomeBanner = () => {
  const sliderImage = [
    {
      title: "You Dream ",
      colorTitle: "IT",
      title2: ", We Build",
      colorTitle2: "IT",
      desc: "",
      urls: Banner1,
    },
    {
      title: "We Make ",
      colorTitle: "IT",
      title2: " Simple and Faster ",
      desc: "",
      urls: Banner2,
    },
    {
      title: "Delivering Technology Solutions",
      desc: "",
      urls: Banner3,
    },
  ];
  const len = sliderImage.length - 1;

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 3000);
    return () => clearInterval(interval);
  }, [activeIndex]);
  return (
    <div className="slider-container">
      <SliderContent activeIndex={activeIndex} sliderImage={sliderImage} />
      <Arrows
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }
      />
      <Dots
        activeIndex={activeIndex}
        sliderImage={sliderImage}
        onclick={(activeIndex) => setActiveIndex(activeIndex)}
      />
      <div className="banner-fade"/>
    </div>
  );
};

export default HomeBanner;
