import React, { useState } from 'react'
import "./AboutComp.scss"
import { Box, Button, Typography } from '@mui/material'

export const AboutComp = () => {
  const [active, setActive] = useState({
    btn1: true,
    btn2: false,
    btn3: false
  })
  return (
    <div className='about-comp-wrapper'>
      <Box maxWidth="lg" className='about-comp tb-space'>
        <div className='left'>
          <img src="/images/team-img.webp" alt="team" />
        </div>
        <div className='right'>
          <div className='top'>
            <Typography className='title' variant='h2'>We are Anke, IT solutions company</Typography>
            <Typography className='text'>Anke company is established in 2021 and affiliated to West International Holdings Group, with the mission of promoting informatization and intelligence from small scale business organizations to large scale industries . Our company is committed on providing professional, standard and easy-to-use informatization, intelligent products and Data integration solution services.</Typography>
          </div>
          <div className='bottom'>
            <div className='bottom-top'>
              <Button onClick={() => setActive({
                btn1: true,
                btn2: false,
                btn3: false
              })} className={active.btn1? 'active': ''}>Our Mission</Button>
              <Button onClick={() => setActive({
                btn1: false,
                btn2: true,
                btn3: false
              })} className={active.btn2? 'active': ''}>Our Vision</Button>
              <Button onClick={() => setActive({
                btn1: false,
                btn2: false,
                btn3: true
              })} className={active.btn3? 'active': ''}>Our Values</Button>
            </div>
            <div className='bottom-text-wrapper'>
            {active.btn1 && <div className='bottom-text'>
            Leading industrial intelligent technology, focusing on R&D and application of intelligent control and information technology in industrial production processes. And become a well-known system integrator in Africa.
            </div>}
           {active.btn2 && <div className='bottom-text'>
           To become a well-known system integrator in Africa,adhering to the business philosophy of "Improving your technology.
            </div>}
            {active.btn3 && <div className='bottom-text'>
            Technological innovation. Industry leadership. Service sharing. Fostering progress, inspiring others, and fostering collaboration for collective growth.
            </div>}
           </div>
          </div>
        </div>
      </Box>
    </div>
  )
}
