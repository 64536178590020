import React, { useState } from 'react'
import './ContactComp.scss'
import { Box, Button, TextField, Typography } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import PlaceIcon from '@mui/icons-material/Place'
import axios from 'axios'

export const ContactComp = () => {
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    message: ''
  })

 // Collecting inputs data
 const handleChange = (e) => {
  setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }))
}

  const sendMessage = () => {
    
    let data = {
      name: inputs.name,
      email: inputs.email,
      message: inputs.message
    }
    axios.post('https://5w5zxcqs9e.execute-api.eu-west-1.amazonaws.com/prod/contact', data)
    document.getElementById("contact-form").reset();
  } 
  return (
    <div className="contact-comp-wrapper">
      <Box maxWidth="lg" className="contact-comp tb-space">
        <div className="top">
          <Button className="title-top-btn">Contact Us</Button>
          <Typography variant="h2" className="top-title">
            Let's Talk! Reach Out to Us Today
          </Typography>
        </div>
        <div className="contact-info">
          <div className="contact-box">
            <div className="icon-outer">
              <div className="icon email">
                <MailIcon className="icon-inner email-inner" />
              </div>
            </div>
            <div className="contact-box-texts">
              <Typography variant='h3'>anke@westholdinggroup.com</Typography>
            </div>
          </div>
          <div className="contact-box">
            <div className="icon-outer">
              <div className="icon phone">
                <PhoneEnabledIcon className="icon-inner phone-inner" />
              </div>
            </div>
            <div className="contact-box-texts">
            <Typography variant='h3'>+251 97 200 6566</Typography>
            </div>
          </div>
          <div className="contact-box">
            <div className="icon-outer">
              <div className="icon address">
                <PlaceIcon className="icon-inner address-inner" />
              </div>
            </div>
            <div className="contact-box-texts">
            <Typography variant='h3'>Sarbet, Gidey Gebrehiwot Building. A.A, Ethiopia</Typography>
                {/* <Typography variant='h3'>Kyalami Hills Estate,  40 Robin Ave, Midrand Gauteng 1685, 
South Africa</Typography> */}
            </div>
          </div>
        </div>
        <form id='contact-form' className='contact-form'>
          <div className='form-top'>
            <TextField
              className='form-name'
              label="Full name"
              name="name"
              onChange={handleChange}
            />
            <TextField  className='form-email'
              label="Email address"
              name="email"
              onChange={handleChange}

            />
          </div>
          <TextField  className='form-msg'
            label="Message"
            rows={6}
            multiline={true}
            name="message"
            onChange={handleChange}
          />
          <Button className='contact-btn' onClick={sendMessage}>Send Message</Button>
        </form>
        <div className='contact-map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.734234736622!2d38.739803765000545!3d8.998228745174718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85481c38c4b5%3A0x293d89980b3eec6!2sAnke%20Co-Creative%20Technologies%20Pty%20Ltd!5e0!3m2!1sen!2set!4v1694689132817!5m2!1sen!2set"></iframe>
        </div>
      </Box>
    </div>
  )
}
