import React from "react";
import { HomeAbout } from "../components/home/HomeAbout/HomeAbout";
import HomeBanner from "../components/home/HomeBanner/HomeBanner";
import Whyus from "../components/home/Whyus/Whyus";
import { Partners } from "../components/home/Partner/Partners";
import { HomeServices } from "../components/home/HomeServices/HomeServices";
import ProjectHome from "../components/home/ProjectsHome/ProjectHome";

export const Home = () => {
  return (
    <>
      <HomeBanner />
      <HomeAbout />
      <HomeServices />
      <Whyus />
      <ProjectHome />
      <Partners />
    </>
  );
};
