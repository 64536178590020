import React from "react";
import "./Banner.scss";
import { Box, Typography } from "@mui/material";

export const Banner = (props) => {
  return (
    <div className="banner-wrapper">
      <Box maxWidth="lg" className="banner tb-space">
        <div className="img-wrapper">
          <img src={props.img} alt="banner" />
        </div>
        <Typography variant="h1" className="title">
          {props.title}
        </Typography>
      </Box>
      <div className="overlay"></div>
      <div className="banner-fade" />
    </div>
  );
};
