import { Box, Button } from "@mui/material";
import React from "react";
import { Typography } from "@mui/material";
import "./Whyus.scss";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

let theme = createTheme();
theme = responsiveFontSizes(theme);

const whyusCards = [
  {
    icon: "pplOriented.svg",
    title: "Peoople-Oriented",
    text: "Understand the customers' expectations.",
  },
  {
    icon: "flexible.svg",
    title: "Flexible",
    text: "Explore diverse view points and multiple perspectives.",
  },
  {
    icon: "afford.svg",
    title: "Affordable",
    text: "Convenient and cost efficient solutions.",
  },
  {
    icon: "pplOriented.svg",
    title: "Adaptable",
    text: "Ability to adapt rapidly to the changing conditions.",
  },
];
const Whyus = () => {
  return (
    <Box
      className="whyus-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box maxWidth="lg" className="whyus-header-box tb-space">
        <ThemeProvider theme={theme}>
          <Typography variant="h2" className="whyus-header">
            Why Choose Our <span className="colored-title">Innovative</span>{" "}
            Solutions
          </Typography>
        </ThemeProvider>
      </Box>

      <Box
        maxWidth="lg"
        className="whyus tb-space"
        sx={{
          display: "flex",
          gap: "24px",
        }}
      >
        {" "}
        <Swiper
          slidesPerView={1}
          spaceBetween={5}
          // pagination={{
          //   clickable: true,
          // }}

          breakpoints={{
            576: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          // pagination={{
          //   type: "progressbar",
          // }}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {whyusCards.map((cardContent) => {
            return (
              <SwiperSlide>
                <Box className="whyus-card">
                  <div className="why-us-icon">
                    <img src={cardContent.icon} alt="" />
                  </div>
                  <ThemeProvider theme={theme}>
                    <Typography className="card-title" variant="subtitle2">
                      {cardContent.title}
                    </Typography>
                    <Typography className="card-text" variant="body2">
                      {cardContent.text}
                    </Typography>
                  </ThemeProvider>
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
};

export default Whyus;
