import {
  Container,
  Box,
  IconButton,
  Button,
  Toolbar,
  AppBar,
  MenuItem,
  Menu,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import logo from '../../../assets/AnkeLogo.png'
import MenuIcon from '@mui/icons-material/Menu'
import { Link, NavLink } from 'react-router-dom'
import { AiOutlineHome, AiOutlineSolution, AiOutlineFundProjectionScreen } from 'react-icons/ai'
import { BsBuildings } from "react-icons/bs"
import { RiContactsLine } from 'react-icons/ri'
import "./Navbar.scss"

const pages = [
  {
    title: 'Home',
    path: '/',
    icon: '<AddHomeOutlinedIcon/>'
  },
  {
    title: 'About',
    path: '/about',
    icon: ''
  },
  {
    title: 'Solutions',
    path: '/solutions/system-solutions',
    icon: ''
  },
  {
    title: 'Projects',
    path: '/projects',
    icon: ''
  },
  {
    title: 'Contact',
    path: '/contact',
    icon: ''
  },
]

export const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div className='navbar-wrapper'>
      <Toolbar sx={{ position: 'relative' }}>
        <AppBar
          className='navbar'
          sx={{
            position: 'fixed',
            top: '0',
            left: '0',
            padding: '0',
            width: '100%',
            px: '16px',
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              p: '0',
            }}
          >
            <Toolbar sx={{ px: '0',justifyContent: "space-between", alignItems: 'center' }}>
              <Box
                sx={{
                  width: '100%',
                  display: { xs: 'flex' },
                  justifyContent: { xs: 'space-between' },
                  alignItems: 'center',
                  padding: '0',
                }}
              >
                {/* Navigation Links wrapper */}
                <Box sx={{ width: "100%", display: 'flex',justifyContent: "space-between", alignItems: 'center' }}>
                  {/* Logo */}
                  <Link to="/" className="logo">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton>
                        <img src={logo} alt="logo" />
                        <div>
                          <span>ANKE CO-CREATIVE</span>
                          <span>Technologies</span>
                        </div>
                      </IconButton>
                    </Box>
                  </Link>
                  {/* Navigation Links */}
                  <Box
                    sx={{
                      display: { xs: 'none', md: 'flex' },
                      marginLeft: { md: '80px', lg: '120px' },
                      a: {
                        textDecoration: 'none',
                      },
                      ">*": {
                        color: '#fff'
                      },
                      '.active': {
                        '>*': {
                        //  home
                        },
                        '&:hover': {
                          borderBottom: '0',
                        },
                      },
                    }}
                  >
                    {pages.map((page) => (
                      <NavLink key={page.path} to={page.path}>
                        <Button
                          key={page}
                          sx={{
                            color: 'black',
                            display: 'block',
                            textTransform: 'none',
                            fontSize: '16px',
                            fontWeight: '500',
                            px: '18px',
                            pt: '16px',
                            pb: '16px',
                            borderRadius: '0'
                          }}
                          className='nav-link'
                        >
                          {page.title}
                        </Button>
                      </NavLink>
                    ))}
                  </Box>
                </Box>
                  {/* Humberger menu */}
                  <Box
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                      p: '0',
                      display: 'flex',
                      alignItems: 'center',
                      ml: '5px',
                    }}
                  >
                    <MenuIcon
                      sx={{
                        color: 'white',
                        width: { xs: 30, sm: 35 },
                        height: { xs: 30, sm: 35 },
                        display: { xs: 'block', md: 'none' },
                      }}
                    />
                  </Box>
                  {/* Hamber menu dropdown */}
                <Menu
                  
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                      a: {
                        textDecoration: 'none',
                        color: 'white',
                      },
                      '.active': {
                        borderBottom: '3px solid #00c4f4',
                        '>*': {
                          color: '#00c4f4',
                        },
                      },
                  }}
                 
                  >
 
                      <NavLink to='/' className='menu-dropdown'>
                        <MenuItem onClick={handleClose}>
                          <span ><AiOutlineHome className='dropdown-icon' /></span><span>Home</span>
                        </MenuItem>
                  </NavLink>
                  
                  <NavLink to='/about' className='menu-dropdown'>
                        <MenuItem onClick={handleClose}>
                          <span ><BsBuildings className='dropdown-icon'/></span><span>About</span>
                        </MenuItem>
                  </NavLink>
                  
                  <NavLink to='/solutions' className='menu-dropdown'>
                        <MenuItem onClick={handleClose}>
                          <span ><AiOutlineSolution className='dropdown-icon' /></span><span>Solutions</span>
                        </MenuItem>
                  </NavLink>
                  
                  <NavLink to='/projects' className='menu-dropdown'>
                        <MenuItem onClick={handleClose}>
                          <span ><AiOutlineFundProjectionScreen className='dropdown-icon'/></span><span>Projects</span>
                        </MenuItem>
                      </NavLink>

                      <NavLink to='/contact' className='menu-dropdown'>
                        <MenuItem onClick={handleClose}>
                          <span ><RiContactsLine className='dropdown-icon'/></span><span>Contact</span>
                        </MenuItem>
                  </NavLink>
                  
                  </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Toolbar>
    </div>
  )
}
