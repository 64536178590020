import React from "react";
import "./ProjectHome.scss";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import project from "../../../assets/projects.jpg";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Grid,
  Typography,
} from "@mui/material";

let theme = createTheme();
theme = responsiveFontSizes(theme);

const ProjectHome = () => {
  const projectCards = [
    {
      image: "ERPSystem.jpg",
      title: "Oracle NetSuite ERP System",
      text: "Understand the customers' expectations.",
    },
    {
      image: "MES.jpg",
      title: "Manufacturing Execution System (MES)",
      text: "Explore diverse view points and multiple perspectives.",
    },
    {
      image: "TMS.jpg",
      title: "Transport Management System (TMS)",
      text: "Convenient and cost efficient solutions.",
    },
    {
      image: "OA.jpg",
      title: "Digital Marketing System",
      text: "Ability to adapt rapidly to the changing conditions.",
    },
    {
      image: "Networking.jpg",
      title: "Networking & CCTV Camera",
      text: "Ability to adapt rapidly to the changing conditions.",
    },
    {
      image: "Weighbridge2.jpg",
      title: "Unattended Weighbridge System",
      text: "Ability to adapt rapidly to the changing conditions.",
    },
  ];
  return (
    <Box
      className="project-container"
      sx={{
        width: "100%",
      }}
    >
      <Box maxWidth="lg" className="project-intro tb-space">
        <Box className="project-title-wrapper">
          <Box maxWidth="lg" className="title-intro-btn">
            <Button className="title-top-btn ">Projects</Button>
          </Box>

          <ThemeProvider theme={theme}>
            <Typography variant="h2" className="project-header">
              Proven Expertise in IT Solutions: <br /> Project Highlights
            </Typography>
          </ThemeProvider>
        </Box>
        <Box className="project-desc-wrapper">
          <ThemeProvider theme={theme}>
            <Typography variant="subtitle1" className="project-header-text">
              Explore our portfolio of accomplished projects where we have
              provided top-notch networking, camera installation, and automation
              services to renowned cement companies. From enhancing security to
              optimizing operational efficiency.
            </Typography>
          </ThemeProvider>
        </Box>
      </Box>
      <Box
        maxWidth="lg"
        className="tb-space"
        sx={{ flexGrow: 1, width: "100%" }}
      >
        <Grid
          container
          rowSpacing={3}
          columnSpacing={3}
          sx={{ display: "flex" }}
          className="grid-wrapper"
        >
          {projectCards.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              rowSpacing={5}
              columnSpacing={3}
              className="project-grid"
            >
              <div className="project-card-wrapper">
                <div className="project-img">
                  <img src={item.image} alt="" />
                </div>
                <div className="project-content">
                  <div className="project-title">{item.title}</div>
                  {/* <div className="project-desc">{item.text}</div> */}
                </div>
                {/* <div className="project-content-hover">
                  <div className="project-hover-top">
                    <div className="proj-title">
                      <h4>Enerprise Resourse Planning</h4>
                    </div>
                    <div className="project-small-img">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                  <div className="project-hover-bottom">
                    <div className="proj-hover-content">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores iure aliquam delectus nulla voluptatum praesentium.</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProjectHome;
