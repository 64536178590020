import React from "react";
import { BsChevronRight } from "react-icons/bs";
import { BsChevronLeft } from "react-icons/bs";

const Arrows = ({ prevSlide, nextSlide }) => {
  return (
    <div className="arrows">
      {" "}
      <span className="prev" onClick={prevSlide}>
        {/* &#10094; */}<BsChevronLeft size={26}/>
      </span>
      <span className="next" onClick={nextSlide}>
        {/* &#10095; */}< BsChevronRight size={26}/>
      </span>
    </div>
  );
};

export default Arrows;
