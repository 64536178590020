import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../../../assets/AnkeFooterLogo.png";
import "./Footer.scss";
import { GrFacebookOption } from "react-icons/gr";
import { FaTwitter } from "react-icons/fa";
import { MdOutlineSettingsPhone, MdOutlineMailOutline } from "react-icons/md";
import { AiOutlineArrowUp } from "react-icons/ai";
import { Link } from "react-router-dom";

export const Footer = () => {
  const handleScroll = () => {
    const top = document.getElementById("top");
    top.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="footer-container">
      <Box maxWidth="lg" className="footer-scroll-box ">
        {/* <div className="footer-scroll-top"> */}
        <button onClick={handleScroll} className="footer-scroller">
          <AiOutlineArrowUp size={24} />
        </button>
        {/* </div> */}
      </Box>
      <Box maxWidth="lg" className="footer-wrapper tb-space">
        <div className="intro-menu-wrapper">
          <div className="footer-intro">
            <div className="footer-logo">
              <div className="footer-logo-wrap">
                <div className="footer-logo-img ">
                  <img src={logo} alt="Footer_Logo" />
                </div>
                <h3 className="logo-text">
                  ANKE CO-CREATIVE <br />
                  Technologies
                </h3>
              </div>
              <div className="footer-intro-text footer-intro-text2 ">
                Committed to providing professional, standardised and
                easy-to-use information technology, intelligent products and
                system integration solution services.
              </div>
              <div className="footer-social-icons">
                <Link 
                  to="https://www.facebook.com/hongkui.chen.58/"
                  target="_blank"
                >
                  <div className="footer-icon">
                    <GrFacebookOption color="white" size={16} />
                  </div>
                </Link>
                <Link to="https://twitter.com/akui88514965" target="_blank">
                  <div className="footer-icon">
                    <FaTwitter color="white" size={16} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="menu-wrapper">
            <div className="menu">
              <div className="menu-title">
                <h5>Menu</h5>
              </div>
              <div>
                <ul className="menu-lists">
                  <Link to="/">
                    <li className="menu-item">Home</li>
                  </Link>
                  <Link to="/about">
                    <li className="menu-item">About Us</li>
                  </Link>
                  <Link to="/solutions/system-solutions">
                    <li className="menu-item">Solution</li>
                  </Link>
                  <Link to="/projects">
                    <li className="menu-item">Projects</li>
                  </Link>
                  <Link to="/contact">
                    <li className="menu-item">Contact</li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="prod-menu">
              <div className="menu-title">
                <h5>Location</h5>
              </div>
              <div className="footer-intro-text">
                Sarbet next to Kings Hotel, Gidey Gebrehiwot Building. <br />
                Addis Ababa, Ethiopia
              </div>
              <div className="footer-intro-text">
                Kyalami Hills Estate, 40 Robin Ave, Midrand Gauteng 1685 <br />
                South Africa
              </div>
            </div>
          </div>
        </div>
        <div className="contact-menu">
          <div className="newsletter-wrapper">
            <div className="footer-contact menu-title">
              <h5>Contact Us</h5>
            </div>
            <div className="footer-intro-text contact-icon">
              <MdOutlineSettingsPhone size={24} />
              <span>+251 97 200 6566</span>
            </div>
            <div className="footer-intro-text contact-icon">
              <MdOutlineMailOutline size={24} />
              anke@westholdinggroup.com
            </div>
          </div>
        </div>
      </Box>
      <Box maxWidth="lg" className="footer-bottom">
        <div className="copy-right">
          © 2021-2022 Anke Co-Creative Technologies Pty Ltd | All rights
          reserved.
        </div>
      </Box>
    </div>
  );
};
