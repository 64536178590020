import { Typography } from '@mui/material'
import React from 'react'
import "./solutions.scss"

export const SolutionCard = (props) => {
  return (
    <div className='solution-card'>
      <div className='icon-wrapper'>
        <img src={props.icon} alt="service-icon" />
      </div>
      <Typography variant='h3' className='card-title'>{props.title}</Typography>
      <Typography className='card-text'>{ props.text}</Typography>
    </div>
  )
}
