import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import "./Partners.scss";
import { Typography } from "@mui/material";

let theme = createTheme();
theme = responsiveFontSizes(theme);

export const Partners = () => {
  const partnerLogo = [
    {
      img: "Cisco_logo.png",
    },
    {
      img: "Dahua_logo.png",
    },
    {
      img: "Huawei_logo.png",
    },
    {
      img: "h3c-logo.png",
    },
    {
      img: "aws-logo.png",
    },

    {
      img: "Hikvision_logo.png",
    },
    {
      img: "Siemens_logo.png",
    },
    {
      img: "Oracle_logo.png",
    },
    {
      img: "ABB_logo.png",
    },
  ];
  const classes = {
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: 20,
    },
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box maxWidth="lg" className="partner-header-box tb-space">
        <ThemeProvider theme={theme}>
          <Typography variant="h2" className="partner-header">
            Our Top Partners
          </Typography>
        </ThemeProvider>
      </Box>
      <Box
        maxWidth="lg"
        className="tb-space"
        sx={{ flexGrow: 1, width: "100%" }}
      >
        <Grid
          container
          rowSpacing={10}
          columnSpacing={3}
          sx={{ display: "flex" }}
          className="grid-wrapper"
        >
          {partnerLogo.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={12 / 5}
              rowSpacing={10}
              columnSpacing={3}
              className="partner-grid"
            >
              {/* // should also add a "key" prop here */}
              <div
                className="partners-img"
                // style={{ fontWeight: "700", textTransform: "capitalize" }}
              >
                <img src={item.img} alt="" />
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
